.rowWhitoutWrap{
  display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: nowrap;
    margin-right: -15px;
    margin-left: -15px;
}
.marginRightRadioBtn{
    margin-right: 65.5px;
}
.marginBottomRadioBtn{
    margin-bottom: 40px;
}
/*Label : réponse*/
.txtLabel{
    font-size: 19pt;
    font-weight: lighter;
    text-align: left;
}
/*conteneur de radio bouton*/
.checkContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Cacher le radio bouton par défaut */
  .checkContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Création du bouton radio custom */
  .radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 44px;
    width: 44px;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(112,112,112);
    border-radius: 50%;
  }
  .radiobtn:focus{
    background-color: rgb(90,200,255);
    outline: none;
    background-color: none;
  }
/*Hover du conteneur de radio bouton*/
.checkContainer:hover input ~ .radiobtn {
    background-color: rgb(90,200,255);
}
/*Pas d'hover sur le bouton radio sur les téléphones*/
@media (hover:none) {
  .checkContainer:hover input ~ .radiobtn{
  background: none;
  }
}
  /* Ajoute le bleu quand le bouton radio est coché */
  .checkContainer input:checked ~ .radiobtn {
    background-color: rgb(90,200,255);
    border: none;
  }
  
  /* Lorsque le bouton radio n'est pas coché */
  .radiobtn:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Montre l'indicateur (point/cercle) lorsque coché */
  .checkContainer input:checked ~ .radiobtn:after {
    display: block;
  }
  
  /* Met le petit point blanc lorsque le bouton est coché */
  .checkContainer .radiobtn:after {
    top: 14px;
    left: 14px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
  }
  /*Changement des boutons radios, label et oui/non selon le média*/
  @media only screen and (max-height: 900px){
    .txtLabel{
      font-size: 16pt;
  }
  }
  @media only screen and (max-width: 700px)
  {
    .txtLabel{
      font-weight: normal;
    }
  }
  @media only screen and (max-width: 575px){
    .checkContainer {
      padding-left:45px;
    }
  }
  @media only screen and (max-width: 479px){
    .marginRightRadioBtn{
      margin-right: 30px;
    }
    .radiobtn {
      height: 33px;
      width: 33px;
    }
    .checkContainer .radiobtn:after {
      top: 11px;
      left: 11px;
      width: 11px;
      height: 11px;
    }
    .txtLabel{
      font-size: 14pt;
    }
    .checkContainer {
      padding-left:35px;
    }
}
@media only screen and (max-width: 350px){
    .radiobtn {
      height: 30px;
      width: 30px;
    }
    .checkContainer .radiobtn:after {
      top: 10px;
      left: 10px;
      width: 10px;
      height: 10px;
    }
    .txtLabel{
      font-size: 12pt;
  }
}


