/*Bouton de choix lorsqu'il est sélectionné*/
.buttonClicked
{
    background-color:rgb(90,200,255);
    border: none;
    color: white;
}
/*Bouton de choix lorsqu'il n'est pas sélectionné*/
.buttonNotClicked
{
    background-color: white;
    border-color: rgb(112,112,112);
    border-width: 1px;
}
/* Propriétés communes aux boutons de choix et commencer*/
.button
{
    width:424px;
    height:68px;
    margin-bottom: 39.5px;
    border-radius: 35px;
    font-size: 25pt;
    font-weight: normal;
    transition: all .2s ease-in-out;
}
.buttonNotClicked:hover, .buttonNotClicked:focus{
    transform: scale(1.1);
	transition: .1s;
}
@media (hover:none) {
    .btnBackNext{
        padding: 14px;
    }
    .buttonNotClicked:hover, .buttonNotClicked:focus{
        transform: scale(1);
    }
}
/* Propriété du bouton Commencer*/
.buttonStart
{
    font-size: 25pt;
    width:424px;
    height:68px;
    border-radius: 35px;
}
/*Enlève les bordures lors du focus des boutons*/
.button:focus, .btnBackNext:focus, .buttonStart:focus, .txtBtnBackNext:focus{
    outline:none;
}
/*Propriétés des boutons retour et suivant*/
.btnBackNext
{
    height: 44px;
    width: 44px;
    border-style:solid;
    border-color: rgb(112,112,112);
    border-width: 1px;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: rgb(112,112,112);
    font-size: 19pt;
    font-weight: lighter;
    font-family: "Arial";
}
/*Propriétés du texte dans le bouton suivant*/
.txtInBtnNext{
    padding-bottom: 2px;
    padding-left: 2px;
}
/*Propriétés du texte dans le bouton retour*/
.txtInBtnBack{
    padding-bottom: 2px;
}
/*Propriété du texte dans les boutons retour et suivant*/
.txtBtnBackNext{
    font-size: 18pt;
    font-weight: normal;
    color: black;
    font-family: inherit;
    border: 0;
    padding: 0;
    background: none;
}
/*Over des boutons retour et suivant*/
.btnBackNextMouseOver{
    background-color: rgb(90,200,255);
    color: white;
    border: none;
}

.txtMarginTextNext{
    margin-right: 10px;
}
.txtMarginTextBack{
    margin-left: 10px;
}
.marginBetweenBtnBackNext{
    margin-left: 160px;
}
/*Tailles des boutons selon le média*/
@media only screen and (max-height: 900px)
{
    .button
    {
        width:400px;
        height:65px;
        margin-bottom: 30px;
        font-size: 20pt;
    }
    .marginBetweenBtnBackNext{
        margin-left: 140px;
    }
    .buttonStart{
        font-size: 20pt;
    }
}
@media only screen and (max-width: 865px){
    .buttonStart
    {
        width:380px;
        height:60px;
        font-size: 20pt;
    }
}
@media only screen and (max-width: 750px){
    .buttonStart
    {
        width:360px;
    }
}
@media only screen and (max-width: 680px){
    .buttonStart
    {
        width:340px;
    }
}
@media only screen and (max-width: 694px){
    .buttonStart
    {
        width:380px;
        height:60px;
    }
}
@media only screen and (max-width: 479px){
    .button
    {
        width:380px;
        height:60px;
        margin-bottom: 30px;
        font-size: 20pt;
    }
    .btnBackNext
    {
        height: 33px;
        width: 33px;
        padding: 10px;
    }  
    .txtBtnBackNext{
        font-size: 16pt;
    }
    .marginBetweenBtnBackNext{
        margin-left: 0px;
    }
}
@media only screen and (max-width: 390px){
    .button
    {
        width:340px;
        height:50px;
        font-size: 18pt;
    }
    .txtBtnBackNext{
        font-size: 14pt;
    }
    .buttonStart
    {
        width:340px;
        height:50px;
        font-size: 18pt;
    }
}
@media only screen and (max-width: 350px){
    .button
    {
        width:295px;
        height:45px;
        margin-bottom: 25px;
        font-size: 15pt;
    }
    .buttonStart
    {
        font-size: 15pt;
        width:295px;
        height:50px;
    }
    .btnBackNext
    {
        height: 30px;
        width: 30px;
        font-size: 16pt;
    }
    .txtBtnBackNext{
        font-size: 11pt;
    }
}
@media only screen and (max-width: 310px){
    .button
    {
        font-size: 13pt;
        width:250px;
        height:40px;
    }
    .buttonStart
    {
        font-size: 13pt;
        width:250px;
        height:40px;
    }
}