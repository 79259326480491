/*Propriété du message de fin*/
.msg{
    font-size: 27pt;
    color: rgb(102,102,102);
    margin-left: 20px;
    margin-right: 20px;
}
/*Propriété du conteneur du message*/
.msgContainer{
    text-align: center;
    margin-top:150px;
    margin-bottom: 135px;
    width:800px;
}
/*Message et conteneur du message selon le média*/
@media only screen and (max-width: 990px){
    .msg{
        font-size: 25pt;
    }
    .msgContainer
    {
        width:auto;
    }
}
@media only screen and (max-width: 800px){
    .msg{
        font-size: 20pt;
    }
}
@media only screen and (max-width: 600px)
{
    .msgContainer{
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 350px){
    .msg{
        font-size: 17pt;
    }
}