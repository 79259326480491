/*Conteneur de l'accueil*/
.contain {
  margin-top: 150px;
  margin-left: 15px;
  margin-right: 15px;
}
/*Message d'accueil*/
.titleWelcome {
  font-size: 25pt;
  font-weight: normal;
  margin-bottom: 7.5px;
}
/*Sélecteur de langue*/
.selectpicker {
  width: max-content;
  height: 30px;
  margin-bottom: 15px;
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
}
/*Division contenant le bouton commencer et le texte d'accueil*/
.start_div {
  margin-right: 15px;
  margin-bottom: 9px;
}
.selectpicker:focus option {
  background-color: white;
  color: black;
}
/*Position du sélecteur de langue*/
.positionSelect {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.marginLogo {
  margin-right: 20px;
}
/*Division contenant le logo CHU et le select (petits écrans)*/
.logoCHU_select_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: baseline;
}
/*Division contenant le logo CHU seulement (grands écrans)*/
.logoCHU_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
/*Message qui dit combien de temps le questionnaire prend à faire*/
.timeMessage {
  font-size: 16pt;
  margin-bottom: 15px;
  max-width: 440px;
}
.logoCRI {
  height: min-content;
}
.versionNumber {
  color: white;
}
/*Accueil selon le média*/
@media only screen and (max-height: 900px) {
  .titleWelcome {
    font-size: 22pt;
  }
  .timeMessage {
    font-size: 14pt;
  }
}
@media only screen and (max-height: 820px) {
  .contain {
    margin-top: 80px;
  }
}
@media only screen and (max-height: 750px) and (max-width: 650px) {
  .contain {
    margin-top: 20px;
  }
}
@media only screen and (max-height: 600px) {
  .contain {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 990px) {
  .titleWelcome {
    font-size: 22pt;
  }
  .timeMessage {
    font-size: 14pt;
  }
}
@media only screen and (max-width: 835px) {
  .logo {
    width: 284px;
    height: 203px;
  }
}
@media only screen and (max-width: 765px) {
  .titleWelcome {
    font-size: 18pt;
    margin-top: 10px;
  }
  .timeMessage {
    font-size: 12pt;
    max-width: 370px;
  }
}
@media only screen and (max-width: 693px) {
  .marginLogo {
    margin-right: 90px;
  }
  /*Position du sélecteur de langue*/
  .positionSelect {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 479px) {
  .contain {
    margin-left: 15px;
    margin-top: 25px;
  }
  .logoCHU {
    width: 90px;
    height: 92px;
  }
  .logoCRI {
    width: 90px;
    height: 35px;
  }
}
@media only screen and (max-width: 390px) {
  .marginLogo {
    margin-right: 80px;
  }
  .timeMessage {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 376px) {
  .marginLogo {
    margin-right: 85px;
  }
  .logo {
    width: 250px;
    height: 179px;
  }
}
@media only screen and (max-width: 340px) {
  .marginLogo {
    margin-right: 60px;
  }
}
@media only screen and (max-width: 315px) {
  .marginLogo {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 350px) {
  .contain {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .titleWelcome {
    font-size: 15pt;
  }
  .timeMessage {
    font-size: 10pt;
  }
}
@media only screen and (max-width: 290px) {
  .logo {
    width: 230px;
    height: 165px;
  }
  .logoCRI {
    width: 70px;
    height: 27px;
  }
  .logoCHU {
    width: 70px;
    height: 71px;
  }
}
