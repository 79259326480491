/*Propriétés du bloque d'erreur*/
#block
{
   background-color: #E82016; 
   width: 999px;
   height: 51px;
   color: white;
   text-align: center;
   font-weight: bold;
   font-size: 20pt;
   border-radius: 8px;
   margin-top: 10px;
}
/*Cache le bloque d'erreur*/
.notVisible{
    visibility: hidden;
}
/*Positionne le bloque*/
.center{
    display: flex;
    justify-content: center;
}
/*Animation du bloque*/
.animate__animated.animate__fadeOut{
    animation-delay: 5s;
}
/* Ajustement du bloque d'erreur selon le média utilisé */
@media only screen and (max-height: 900px){
    #block{
        height: 38px;
        font-size: 18pt;
    }
}
@media only screen and (max-width: 1030px){
    #block{
        width: 800px;
    }
}
@media only screen and (max-width: 850px){
    #block{
        width: 700px;
    }
}
@media only screen and (max-width: 750px){
    #block{
        width: 600px;
    }
}
@media only screen and (max-width: 650px){
    #block{
        width: 500px;
    }
}
@media only screen and (max-width: 550px){
    #block{
        width: 450px;
        font-size: 16pt;
    }
}
@media only screen and (max-width: 460px){
    #block{
        height: 30px;
        width: 400px;
        font-size: 15pt;
        margin-top: 5px;
    }
}
@media only screen and (max-width: 410px){
    #block{
        width: 370px;
    }
}
@media only screen and (max-width: 375px){
    #block{
        width: 350px;
    }
}
@media only screen and (max-width: 340px){
    #block{
        width: 300px;
        height: 25px;
        font-size: 13pt;
    }
}
@media only screen and (max-width: 310px)
{
    #block{
        width: 270px;
    }
}
