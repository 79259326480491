/*Position des boutons retour et suivant lors du message de fin */
.btn-position{
    text-align: center;
    display:flex;
    flex-direction: row;
    justify-content: center;
}
/*Propriétés du paragraphe de chargement*/
.loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}
/* Les boutons ne seront pas collés sur cellulaires*/
@media only screen and (max-width: 479px){
.btn-position{
    justify-content: space-around;
}
}