.containerRadioBtn{
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    width:700px;
}
.marginBottomYesNo{
    margin-bottom: 29px;
}
.marginYes{
    margin-left: 15px;
  }
.marginNo{
    margin-left: 50px;
}
.yesNo{
    font-size: 23pt;
    font-weight: lighter;
}
/*Grosseur et marges des textes Oui et Non des boutons radio selon le média*/
@media only screen and (max-height: 900px){
  .yesNo{
    font-size: 20pt;
  }
  .marginNo{
    margin-left: 60px;
  }
  .marginBottomYesNo{
    margin-bottom: 18px;
  }
}
@media only screen and (max-width: 770px)
{
    .marginYes{
      margin-left: 10px;
    }
    .marginNo{
      margin-left: 55px;
    }
}
@media only screen and (max-width: 700px)
{
  .yesNo{
    font-weight: normal;
  }
}
@media only screen and (max-width: 590px)
{
    .marginNo{
      margin-left: 57px;
    }
}
@media only screen and (max-width: 575px)
{
  .marginNo{
    margin-left: 53px;
  }
  .containerRadioBtn{
    text-align: right;
    width: auto;
  }
}
@media only screen and (max-width: 479px){
  .containerRadioBtn{
    text-align: right;
    width: auto;
  }
    .yesNo{
      font-size: 17pt;
    }
    .marginNo{
      margin-left: 25px;
    }
}
@media only screen and (max-width: 350px){
    .yesNo{
      font-size: 14pt;
    }
    .marginNo{
      margin-left: 37px;
    }
}