/*La question*/
.title
{
    font-size: 30pt;
    font-weight: normal;
    max-width: 700px;
}
/*Marges des questions de type 1 Oui/Non*/
.marginType1
{
    margin-top: 30px;
    margin-bottom: 38px;
    text-align: center;
}
/*Marges des questions de type 2 (multiples)*/
.marginTitleType2
{
    margin-top: 10px;
    margin-bottom: 46px;
    text-align:left;
}
/*Position des boutons retour et suivant*/
.backNextPosition{
    justify-content: center;
    margin-top: 25px;
}
/*Marges du conteneur de la question*/
.marginContainer{
    margin-left: 15px;
    margin-right: 15px;
}
.container-flex{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
/*Questions selon le média*/
@media only screen and (max-height: 900px){
    .title{
        font-size: 25pt;
    }
    .marginType1
    {
        margin-top: 20px;
    }
    .marginTitleType2
    {
        margin-bottom: 23px;
    }
}
@media only screen and (max-width: 575px){
    .marginTitleType2
    {
        margin-bottom: 10px;
    }
    .backNextPosition{
        justify-content: space-between;
    }
}
@media only screen and (max-width: 479px)
{
    .title
    {
        font-size: 25pt;
        width:auto;
    }
}
@media only screen and (max-width: 390px){
    .title
    {
        font-size: 23pt;
    }
}
@media only screen and (max-width: 350px){
    .title
    {
        font-size: 20pt;
    }
}
@media only screen and (max-width: 310px){
    .title
    {
        font-size: 18pt;
    }
}